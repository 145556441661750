import { ReactChild } from 'react'

interface Props {
  children: ReactChild,
  height?: number,
  width?: number,
}

export default function DisplayContainer({ children, height, width }: Props) {
  return (
    <div className={`display-container border-4 w-full h-full ${height && 'border-yellow-500'}`} style={{ height, width }}>
      {children}
    </div>
  )
}
