interface Props {
  connecting: boolean;
  reconnect: () => void;
}

export default function JoinGameDisplay({ reconnect, connecting }: Props) {
  return (
    <div>
      {
        connecting ?
          <div>
            Connecting...
            <button className="button" type="submit" onClick={() => window.location.replace('/')}>Exit</button>
          </div>
          :
          <div>
            You have exited the table.
            <button className="button" type="submit" onClick={() => reconnect()}>Reconnect</button>
            <button className="button" type="submit" onClick={() => window.location.replace('/')}>Go Home</button>
          </div>
      }
    </div>
  )
}
