import React, { FC } from "react";

import { PubSub, ServerProxyFirebase } from '../models/server';
import DisplayContainer from '../components/DisplayContainer';
import ClientController from '../components/ClientController';
import { TableId } from "../models/types";


const size = { height: 400, width: 400 };
const names = 'ABCDEFG'


const MultiScreenPage: FC = () => {
  // @ts-ignore
  globalThis.pubsub ??= new PubSub();

  // const [createTableId, setCreateTableId] = React.useState('');
  const storedClients = JSON.parse(localStorage.getItem('clients') || '[]');
  const counter = React.useRef(storedClients.length);
  const [playerIds, setPlayerIds] = React.useState<string[]>(storedClients);
  React.useEffect(() => {
    localStorage.setItem('clients', JSON.stringify(playerIds));
  }, [playerIds]);


  const onAction = React.useCallback((action: 'remove' | 'add', name: string) => {
    if (action === 'remove') {
      setPlayerIds(playerIds => playerIds.filter(x => x !== name));

    } else if (action === 'add') {
      setPlayerIds(playerIds => playerIds.flatMap(x => x === name ? [x, names[counter.current]] : x));
      counter.current++;
      return counter;

    } else {
      throw new Error('never');
    }
  }, []);

  React.useEffect(() => {
    if (playerIds.length === 0) {
      setPlayerIds([names[0], names[1]]);
      counter.current = 1;
    }
  }, [playerIds]);

  return (
    <div>
      {/* <input className="p-1 m-1 border rounded" placeholder="Create Table" value={createTableId} onChange={(e) => setCreateTableId(e.target.value)} onKeyUp={(e) => {
        if (e.key === 'Enter') {
          (new PokerServerProxyMock()).createTable(Number(createTableId));
          setCreateTableId('');
        }
      }} /> */}
      <div className="button" onClick={() => new ServerProxyFirebase().createTable(String(1))}>Create/Reset Table</div>
      <div className="flex flex-wrap gap-1">
        {playerIds.map((playerId) => <DisplayContainerWithDebug key={playerId} onAction={onAction} size={size} name={playerId} tableId={'1'} />)}
      </div>
    </div>
  )
}

function DisplayContainerWithDebug(props: {
  name: string,
  size: Record<string, number>,
  onAction: (action: 'remove' | 'add', name: string) => void,
  tableId: TableId,
}) {
  return (
    <div className="flex flex-col">
      <DisplayContainer {...props.size}>
        <ClientController
          playerId={props.name}
          tableId={props.tableId}
          onTableDNE={() => console.warn('Table does not exist')}
          useServer='local'
        />
      </DisplayContainer>
      <div className="flex">
        <div className="button" onClick={() => props.onAction('remove', props.name)}>remove</div>
        <div className="button" onClick={() => props.onAction('add', props.name)}>add to right</div>
      </div>
    </div>
  );
}


export default MultiScreenPage;
