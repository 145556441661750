import { FC } from "react";
import DisplayContainer from '../components/DisplayContainer';
import ClientController from '../components/ClientController';

const JoinPage: FC = () => {
  const tableId = window.location.pathname.replace('/', '');
  return (
    <DisplayContainer>
      <ClientController
        tableId={tableId}
        onTableDNE={() => {
          window.location.replace('/#table-dne');
        }}
        useServer='remote'
      />
    </DisplayContainer>
  )
}

export default JoinPage;
