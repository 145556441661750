import React from 'react'

import { findPlayer, objectSumValues } from '../models/helpers'
import { Table, Game } from '../models/poker'
import { PlayerAction, WLSOption, TableId } from '../models/types'

import PrimaryGameDisplay from './PrimaryGameDisplay'
import JoinGameDisplay from './JoinGameDisplay'
import SetupGameDisplay from './SetupGameDisplay'
import ShowdownDisplay from './ShowdownDisplay'
import { ServerProxyFirebase, ServerProxyLocal, ServerProxyInterface } from '../models/server'

interface Props {
  playerId?: string;
  tableId: TableId;
  onTableDNE: () => void;
  useServer: 'remote' | 'local';
}

interface State {
  connecting: boolean;
  playerId: string;
  isConnected: boolean;
  table: Table | null;
}

export default class ClientController extends React.Component<Props, State> {
  state: State = {
    connecting: false,
    playerId: this.props.playerId ?? '',
    isConnected: false,
    table: null,
  }

  proxy!: ServerProxyInterface;

  componentDidMount(): void {
    if (!this.proxy) {
      if (this.props.useServer === 'remote') {
        this.proxy = new ServerProxyFirebase();
      } else {
        this.proxy = new ServerProxyLocal();
      }
      this.proxy.registerHandlers({
        onConnect: this.onServerConnected,
        onDisconnect: this.onServerDisconnected,
        onUpdate: this.onServerUpdate,
        onTableDNE: this.onServerTableDNE,
      });
      this.connect();
    }
  }

  connect = () => {
    this.setState({ connecting: true });
    this.proxy.joinTable(this.props.tableId);
  }

  onServerConnected = (table: Table) => {
    this.setState({ table, connecting: false });
    if (this.state.playerId !== '' && !table.players.includes(this.state.playerId)) {
      table.addNewPlayer(this.state.playerId);
      this.proxy.updateTable(table);
    }
    this.setState({ isConnected: true });
  }

  onServerDisconnected = () => {
    this.setState({ isConnected: false, connecting: false });
  }

  onServerUpdate = (table: Table) => {
    this.setState({ table, isConnected: true, connecting: false });
  }

  onServerTableDNE = () => {
    this.setState({ connecting: false });
    this.props.onTableDNE();
  }

  disconnect = () => {
    this.proxy.exitTable();
    this.setState({ isConnected: false, table: null, connecting: false });
  }

  onAction = (action: PlayerAction) => {
    if (!this.state.table?.currentGame)
      throw new Error('Unexpected undefined game');
    this.state.table.currentGame.act(action);
    this.proxy.updateTable(this.state.table);
  }

  onChooseWLS = (potIndex: number, action: WLSOption) => {
    if (!this.state.table?.currentGame)
      throw new Error('Unexpected undefined game');
    this.state.table.currentGame.setPotResultForPlayer(this.state.playerId, potIndex, action);
    this.proxy.updateTable(this.state.table);
  }

  render() {
    const { table, playerId } = this.state;
    const game = table?.currentGame;

    return (
      <>
        {
          table === null ?
            <JoinGameDisplay
              connecting={this.state.connecting}
              reconnect={() => { this.connect() }}
            />
            :
            !game ?
              <SetupGameDisplay
                isDisconnected={!this.state.isConnected}
                disconnect={() => this.disconnect()}
                table={table}
                tableId={this.props.tableId}
                playerId={this.state.playerId}
                setPlayerId={(playerId) => {
                  this.setState({ playerId });
                  if (playerId !== '') {
                    this.state.table!.addNewPlayer(playerId);
                    this.proxy.updateTable(this.state.table!);
                  }
                }}
                onUpdate={(newTable: Table) => this.proxy.updateTable(newTable)}
              />
              :
              game.currentRound === 'showdown' ?
                <ShowdownDisplay
                  isDisconnected={!this.state.isConnected}
                  currentGame={game}
                  potInfos={game.getPotResultsForPlayer(playerId)}
                  onChooseWLS={this.onChooseWLS}
                  canStartNextGame={game.isAllPotsSettled}
                  newStackAmount={!game.isAllPotsSettled ? -1 : (game.getWinnerChipMap()[playerId] ?? 0) + game.gameStacks[playerId]}
                  onGotoSetupScreen={() => { table.addCurrentGameToPreviousGames(); this.proxy.updateTable(table); }}
                  onStartNewGame={() => { table.addCurrentGameToPreviousGames(); table.currentGame = table.initializeNewGame(); this.proxy.updateTable(table); }}
                />
                :
                <PrimaryGameDisplay
                  currentRound={game.currentRound}
                  isDisconnected={!this.state.isConnected}
                  isMyTurn={game.currentPlayer === playerId}
                  myStack={game.gameStacks[playerId]}
                  potSizes={game.pots.map(p => p.amount)}
                  position={extractPosition(game, playerId)}
                  actions={game.currentPlayer === playerId ? game.currentPlayerActions : []}
                  potCurrentRound={objectSumValues(game.currentBets)}
                  bettedByMe={game.currentBets[playerId] ?? 0}
                  state={extractDisplayStateFromGame(game, playerId)}
                  disconnect={() => this.disconnect()}
                  act={(a: PlayerAction) => this.onAction(a)}
                />
        }
      </>
    )
  }
}

function extractPosition(game: Game, player: string): ('sb' | 'bb' | 'utg' | 'button')[] {
  return [
    findPlayer(game.allPlayers, game.buttonPlayer, 'utg') === player && 'utg',
    findPlayer(game.allPlayers, game.buttonPlayer, 'sb') === player && 'sb',
    findPlayer(game.allPlayers, game.buttonPlayer, 'bb') === player && 'bb',
    game.buttonPlayer === player && 'button',
  ].filter(Boolean) as any;
}

type ClientState = 'folded' | 'sit-out' | 'all-in' | 'checked' | 'raised' | 'called' | 'default';
function extractDisplayStateFromGame(game: Game, player: string): ClientState {
  if (game.foldedPlayers.includes(player))
    return 'folded';

  if (game.allInPlayers.includes(player))
    return 'all-in';

  if (!game.allPlayers.includes(player))
    return 'sit-out';

  if (game.actQueue.includes(player))
    return 'default';

  const playerBet = game.currentBets[player] ?? 0;
  const maxBetExceptPlayer = Math.max(0, ...Object.entries(game.currentBets).filter(([p, _]) => p !== player).map(([_, x]) => x));
  if (playerBet === 0)
    return 'checked';

  if (playerBet === maxBetExceptPlayer)
    return 'called';

  if (playerBet > maxBetExceptPlayer)
    // return 'raised'; TODO: Currently there is no way to determine if the player raised
    return 'called';

  throw new Error('?');
}
