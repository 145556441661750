import { FC } from 'react';
import { QrCode, Ecc } from './qr-code-gen-lib';


export const QrCodeSvg: FC<{
  text: string,
  fgColor: string,
  bgColor: string,
}> = ({ text, fgColor, bgColor }) => {
  const qr = QrCode.encodeText(text, Ecc.LOW);
  const border = 3;

  let parts: string[] = [];
  for (let y = 0; y < qr.size; y++) {
    for (let x = 0; x < qr.size; x++) {
      if (qr.getModule(x, y))
        parts.push(`M${x + border},${y + border}h1v1h-1z`);
    }
  }

  // https://github.com/nayuki/QR-Code-generator/blob/master/typescript-javascript/qrcodegen-input-demo.ts?ts=4#L177
  return (
    <svg viewBox={`0 0 ${qr.size + border * 2} ${qr.size + border * 2}`} stroke="none">
      <rect width="100%" height="100%" fill={`${bgColor}`} />
      <path d={`${parts.join(" ")}`} fill={`${fgColor}`} />
    </svg>
  );
}
