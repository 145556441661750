import { Game } from "../models/poker";
import { WLSOption } from "../models/types";


interface PotInfo {
  text: string;
  choiceNeeded: boolean;
  settled: boolean;
  chosenOption?: WLSOption;
  hintedOption?: WLSOption;
  potAmount: number;
}

interface Props {
  isDisconnected: boolean;
  currentGame: Game;
  potInfos: PotInfo[];
  onChooseWLS: (potIndex: number, option: WLSOption) => void;
  canStartNextGame: boolean;
  onGotoSetupScreen: () => void;
  onStartNewGame: () => void;
  newStackAmount: number;
}

export default function ShowdownDisplay(props: Props) {
  return (
    <div className={`h-full w-full primary-game-display-transition p-5 ${props.isDisconnected && 'container-disconnected'}`}>
      {
        props.potInfos.map((potInfo, potIndex) => (
          <div key={potIndex} className="flex flex-col items-center border-2 py-2">
            <div className="text-center">{potInfo.text || 'settled'}</div>
            <div className="text-xl">Pot: {potInfo.potAmount}</div>
            {!potInfo.choiceNeeded ? null :
              <div className='flex items-center'>
                {
                  [WLSOption.Win, WLSOption.Lose, WLSOption.Split].map(option => (
                    <Button
                      key={option}
                      type={option}
                      isSelected={option === potInfo.chosenOption}
                      pulsing={option === potInfo.hintedOption && potInfo.hintedOption !== potInfo.chosenOption}
                      onClick={() => props.onChooseWLS(potIndex, option)}
                    />
                  ))
                }
              </div>
            }
          </div>
        ))
      }

      {
        props.canStartNextGame ?
          <>
            <div>newStackAmount: {props.newStackAmount}</div>
            <div className="button" onClick={() => props.onStartNewGame()}>New Game »»</div>
            <div className="button" onClick={() => props.onGotoSetupScreen()}> «« Table setup (adjust chips, invite players)</div>
          </>
          :
          <div className="button cursor-not-allowed bg-zinc-300">Settle all pots to continue</div>
      }
    </div>
  )
}

function Button(props: {
  onClick: () => void,
  type: WLSOption,
  pulsing: boolean,
  isSelected: boolean,
}) {
  let text = props.type;

  const color = props.type === WLSOption.Win ? 'bg-green-200' : props.type === WLSOption.Lose ? 'bg-red-200' : 'bg-yellow-200';

  return (
    <button
      className={`button p-3 mx-1 ${props.isSelected && ' border-black'} ${color} ${props.pulsing && 'showdown-button-pulse'}`}
      onClick={() => props.onClick()}>
      {text}
    </button>
  )
}
