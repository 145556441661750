import * as fdb from 'firebase/database';
import { Table } from '../poker';
import { TableId } from '../types';
import { ServerProxyInterface } from './types';
import { PokerHandlers } from './types';

// @ts-ignore
const database: fdb.Database = window.firebase.database;

export default class ServerProxyFirebase implements ServerProxyInterface {
  private handlers?: PokerHandlers;
  private tableId?: TableId;
  private onConnectCalled = false;
  private unsubscribe1?: fdb.Unsubscribe;
  private unsubscribe2?: fdb.Unsubscribe;

  registerHandlers(handlers: PokerHandlers) {
    if (this.handlers)
      throw new Error('Handlers are already registered');
    this.handlers = handlers;
  }

  async createTable(tableId?: string) {
    tableId ??= String(Math.floor(Math.random() * 1000 + 1000));

    const table = Table.getEmptyTable();
    const tableRef = fdb.ref(database, `tables/${tableId}`);
    await fdb.set(tableRef, JSON.stringify(table));
    return tableId;
  }

  joinTable(tableId: TableId) {
    const tableRef = fdb.ref(database, `tables/${tableId}`);
    this.tableId = tableId;
    this.unsubscribe1 = fdb.onValue(tableRef, (snapshot) => {
      if (!snapshot.val()) {
        this.handlers!.onTableDNE();
        return;
      }
      const table = new Table(JSON.parse(snapshot.val()));
      if (this.onConnectCalled) {
        this.handlers!.onUpdate(table);
      } else {
        this.handlers!.onConnect(table);
        this.onConnectCalled = true;
      }
    });

    // https://firebase.google.com/docs/database/web/offline-capabilities?authuser=0#section-connection-state
    const connectedRef = fdb.ref(database, ".info/connected");
    this.unsubscribe2 = fdb.onValue(connectedRef, (snap) => {
      if (snap.val() === true) {
        console.log("connected");
      } else {
        console.log("not connected");
        this.handlers!.onDisconnect();
      }
    });
  }

  updateTable(table: Table) {
    fdb.set(fdb.ref(database, `tables/${this.tableId}`), JSON.stringify(table));
  }

  exitTable() {
    if (this.unsubscribe1) this.unsubscribe1();
    if (this.unsubscribe2) this.unsubscribe2();
    this.onConnectCalled = false;
    this.tableId = undefined;
  }
}
