// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB6fgkv4oVdccHAJN1AldQ_SvOvxQGx1ow",
  authDomain: "vchipsdotapp.firebaseapp.com",
  projectId: "vchipsdotapp",
  storageBucket: "vchipsdotapp.appspot.com",
  messagingSenderId: "985765057374",
  appId: "1:985765057374:web:051cc037965e9a0bceb5cf",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

window.firebase = {
  app,
  analytics: getAnalytics(app),
  database: getDatabase(app),
};
