import { AssertionFailed } from "./errors";

/*
Assert.never('msg');
Assert(true, 'msg');
Assert.defined(value, 'msg');
Assert.expect(v1).eq(v2, 'message');
*/
const Assert = function (value: boolean, msg?: string) {
  if (!value) {
    throw new AssertionFailed(msg ?? 'Expect ' + value + ' to be truthy');
  }
}

Assert.never = (msg?: string) => {
  throw new AssertionFailed(msg ?? 'This should never be executed');
}

Assert.expect = <T>(actualValue: T) => {
  return {
    'beEmpty'(msg?: string) {
      if (Array.isArray(actualValue)) {
        if (!(actualValue.length === 0)) throw new AssertionFailed(msg ?? `Expect array to be empty, got ${actualValue}`);
      } else if (typeof actualValue === 'string') {
        if (!(actualValue.length === 0)) throw new AssertionFailed(msg ?? `Expect string to be empty, got ${actualValue}`);
      } else if (typeof actualValue === 'object' && actualValue !== null) {
        if (!(Object.keys(actualValue).length === 0)) throw new AssertionFailed(msg ?? `Expect object to be empty, got ${actualValue}`);
      } else {
        throw new AssertionFailed(`Do not know what is empty for ${actualValue}`);
      }
    },
    'beNonEmpty'(msg?: string) {
      if (Array.isArray(actualValue)) {
        if (actualValue.length === 0) throw new AssertionFailed(msg ?? `Expect array to be non-empty, got ${actualValue}`);
      } else if (typeof actualValue === 'string') {
        if (actualValue.length === 0) throw new AssertionFailed(msg ?? `Expect string to be non-empty, got ${actualValue}`);
      } else if (typeof actualValue === 'object' && actualValue !== null) {
        if (Object.keys(actualValue).length === 0) throw new AssertionFailed(msg ?? `Expect object to be non-empty, got ${actualValue}`);
      } else {
        throw new AssertionFailed(`Do not know what is empty for ${actualValue}`);
      }
    },
    'beTruthy'(msg?: string) {
      if (!actualValue) throw new AssertionFailed(msg ?? `Expect ${msg} to be truthy`);
    },
    'beFalsy'(msg?: string) {
      if (actualValue) throw new AssertionFailed(msg ?? `Expect ${msg} to be falsy`);
    },
    'eq'(expectedValue: T, msg?: string) {
      if (!(actualValue === expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} === ${expectedValue}`);
    },
    'ne'(expectedValue: T, msg?: string) {
      if (!(actualValue !== expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} !== ${expectedValue}`);
    },
    'gt'(expectedValue: T, msg?: string) {
      if (!(actualValue > expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} > ${expectedValue}`);
    },
    'ge'(expectedValue: T, msg?: string) {
      if (!(actualValue >= expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} >= ${expectedValue}`);
    },
    'lt'(expectedValue: T, msg?: string) {
      if (!(actualValue < expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} < ${expectedValue}`);
    },
    'le'(expectedValue: T, msg?: string) {
      if (!(actualValue <= expectedValue)) throw new AssertionFailed(msg ?? `Expect ${actualValue} <= ${expectedValue}`);
    },
    // '==='(expectedValue: T, msg?: string) {
    //   if (!(actualValue === expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} === ${expectedValue}`);
    // },
    // '!=='(expectedValue: T, msg?: string) {
    //   if (!(actualValue !== expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} !== ${expectedValue}`);
    // },
    // '>='(expectedValue: T, msg?: string) {
    //   if (!(actualValue >= expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} >= ${expectedValue}`);
    // },
    // '<='(expectedValue: T, msg?: string) {
    //   if (!(actualValue <= expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} <= ${expectedValue}`);
    // },
    // '>'(expectedValue: T, msg?: string) {
    //   if (!(actualValue > expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} > ${expectedValue}`);
    // },
    // '<'(expectedValue: T, msg?: string) {
    //   if (!(actualValue < expectedValue)) throw new AssertionError(msg ?? `Expect ${actualValue} < ${expectedValue}`);
    // },
  }
}

export default Assert;
