import { FC } from "react";
import { Clear, StayCurrentPortrait } from '@mui/icons-material';
import { ServerProxyFirebase } from "../models/server";

const HomePage: FC = () => {
  const banner = window.location.hash;

  return (
    <div className="m-3 flex flex-col">
      <div className="invisible lg:visible border bg-yellow-200 p-3 rounded">
        <span className="mx-1"><StayCurrentPortrait /></span>
        This app is design for portrait mobile.
      </div>

      {
        banner === '#table-dne' &&
        <div className="border border-red-400 bg-red-50 rounded m-2 p-2 flex justify-between">
          The table doesn't exist or isn't accepting new players.
          <div onClick={() => window.location.replace('/')}>
            <Clear />
          </div>
        </div>
      }

      <div className="font-black text-5xl my-20">

        <div className="py-5 self-center bg-clip-text text-transparent bg-gradient-to-t from-yellow-500 to-green-500">
          <span><em>vchips.app: </em></span>
          Virtual chips for poker.
        </div>
        <div className="animate-pulse text-yellow-500">Coming soon.</div>
      </div>

      <div className="button" onClick={async () => {
        const tableId = await new ServerProxyFirebase().createTable();
        console.log(tableId);
        window.location.replace('/' + tableId);
      }}>
        Create Table
      </div>

      <div className="button" onClick={() => {
        window.location.replace('/' + prompt('Table id?'));
      }}>
        Join Table
      </div>
    </div>
  )
}

export default HomePage;
