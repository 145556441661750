export type ChipAmount = number;
export type PlayerId = string;
export type GameRoundName = "pre-flop" | "flop" | "turn" | "river" | "showdown";

export type PlayerChipMap = Record<PlayerId, ChipAmount>;

export interface PlayerActionFold {
  type: "fold";
}

export interface PlayerActionCheck {
  type: "check";
}

export interface PlayerActionCall {
  type: "call";
  allIn: boolean;
  addedAmount: ChipAmount;
  totalAmount: ChipAmount;
}

export interface PlayerActionRaise {
  type: "raise";
  allIn: boolean;
  addedAmount: ChipAmount;
  totalAmount: ChipAmount;
}

export type PlayerAction = PlayerActionFold | PlayerActionCheck | PlayerActionCall | PlayerActionRaise;

export enum WLSOption {
  Win = 'win',
  Lose = 'lose',
  Split = 'split',
}

export interface Pot {
  amount: ChipAmount;
  players: PlayerId[];
}

export type TableId = string;
