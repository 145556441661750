import React from 'react'
import type { PlayerAction, GameRoundName, PlayerActionRaise } from '../models/types'


type ClientState = 'folded' | 'sit-out' | 'all-in' | 'checked' | 'raised' | 'called' | 'default';

interface Props {
  isDisconnected: Boolean;
  isMyTurn: Boolean;
  state: ClientState;
  potSizes: number[];
  myStack: number;
  potCurrentRound: number;
  bettedByMe: number;
  actions: PlayerAction[];
  currentRound: GameRoundName;
  position: ('sb' | 'bb' | 'utg' | 'button')[];
  disconnect: () => void;
  act: (action: PlayerAction) => void;
}

export default function PrimaryGameDisplay(props: Props) {
  const [raiseInput, setRaiseInput] = React.useState(String((props.actions.filter(a => a.type === 'raise')[0] as PlayerActionRaise)?.addedAmount ?? -1));
  React.useEffect(() => {
    setRaiseInput(String((props.actions.filter(a => a.type === 'raise')[0] as PlayerActionRaise)?.addedAmount ?? -1));
  }, [props.actions]);

  const onRaise = (action: PlayerActionRaise) => {
    const addedAmount = Math.min(Number(raiseInput), props.myStack);

    if (action.allIn && addedAmount !== props.myStack)
      throw new Error('Must all-in');

    props.act({
      type: 'raise',
      allIn: addedAmount === props.myStack,
      addedAmount: addedAmount,
      totalAmount: props.bettedByMe + addedAmount,
    });
  }

  return (
    <div className={`h-full w-full primary-game-display-transition p-5 container-${props.state} ${props.isDisconnected && 'container-disconnected'} ${props.isMyTurn && 'container-my-turn'}`}>
      <button className="button" onClick={() => props.disconnect()}>Disconnect</button>
      <div className="text-xl">State: {props.state}</div>
      <div className="text-xl">Position: {props.position.join(', ')}</div>
      <div className="text-xl">Current Round: {props.currentRound}</div>
      <div className="text-xl">Pot from previous rounds: {props.potSizes.toString()}</div>
      <div className="text-xl">Pot from current round: {props.potCurrentRound}</div>
      <div className="text-xl">My Stack: {props.myStack}</div>
      <div className="text-xl">I Betted this round: {props.bettedByMe}</div>
      <div className="grid grid-cols-3">
        {
          props.actions.map(action =>
            <div
              className="button"
              key={action.type}
              onClick={() => action.type !== 'raise' && props.act(action)}
            >
              {action.type === 'fold' ? <div>Fold</div> :
                action.type === 'call' ? <div>Call {action.addedAmount} {action.allIn && '(All-In)'}</div> :
                  action.type === 'check' ? <div>Check</div> :
                    action.type === 'raise' ?
                      <>
                        <span onClick={() => onRaise(action)}>Raise to {Number(raiseInput) === props.myStack && '(All-in)'}</span>
                        <input
                          className="w-full"
                          type="number"
                          value={raiseInput}
                          min={action.addedAmount}
                          max={props.myStack}
                          onChange={(e) => setRaiseInput(e.target.value)}
                          onBlur={() => setRaiseInput(String(Math.min(props.myStack, Math.max(Number(raiseInput), action.addedAmount))))}
                        />
                      </>
                      :
                      <>Error</>
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

// function SVG({ type }: { type: 'check' | 'raise' | 'folded' }) {
//   switch (type) {
//     case '':
//       return (
//         <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
//           <path stroke-width="10" id="svg_6" d="m15.84159,53.96038l23.76237,29.20794l41.58417,-64.35644" opacity="NaN" stroke="#000000" fill="#fff0" />
//         </svg>
//       )
//   }
// }
