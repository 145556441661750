import Assert from "./assert";
import { findPlayer, generateQueueForRound, isConsistent, deriveHintedOption } from "./helpers";
import { ChipAmount, PlayerId, PlayerChipMap, Pot, GameRoundName, WLSOption, PlayerAction } from "./types";
import * as R from 'ramda';

interface GameSettings {
  smallBlindAmount: ChipAmount;
  bigBlindAmount: ChipAmount;
  defaultStartingAmount: ChipAmount;
}

export interface TableInterface {
  players: PlayerId[];
  tableStacks: PlayerChipMap;
  previousGames: GameInterface[];
  currentGame: Game | null;
  settings: GameSettings;
}

export interface GameInterface {
  allPlayers: PlayerId[];
  buttonPlayer: PlayerId;
  gameStacks: PlayerChipMap;
  pots: Pot[];
  currentRound: GameRoundName;
  currentBets: PlayerChipMap;
  allInPlayers: PlayerId[];
  foldedPlayers: PlayerId[];
  settledQueue: PlayerId[];
  actQueue: PlayerId[];
  potResults: Record<PlayerId, WLSOption>[];
  settings: GameSettings;
}

export class Table implements TableInterface {
  buttonPlayer?: PlayerId;
  players!: string[];
  tableStacks!: PlayerChipMap;
  previousGames!: GameInterface[];
  currentGame: Game | null = null;
  settings!: GameSettings;

  static getEmptyTable() {
    return new Table({
      players: [],
      previousGames: [],
      tableStacks: {},
      currentGame: null,
      settings: {
        smallBlindAmount: 5,
        bigBlindAmount: 10,
        defaultStartingAmount: 1000,
      },
    });
  }

  fromJSON(s: string) {
    Object.assign(this, JSON.parse(s));
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  };
  toJSON() { return this; }
  copy() { return new Table(JSON.parse(JSON.stringify(this))); }

  constructor(obj: TableInterface) {
    Object.assign(this, obj);
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  }

  initializeNewGame() {
    if (this.players.length <= 1)
      throw new Error('Cannot start game with 1 or less people');
    this.buttonPlayer ??= this.players[0];

    const sb = findPlayer(this.players, this.buttonPlayer, 'sb');
    const bb = findPlayer(this.players, this.buttonPlayer, 'bb');
    const gameStacks = Object.assign({}, this.tableStacks);
    // TODO: Check if they have that amount of chips
    gameStacks[sb] -= this.settings.smallBlindAmount;
    gameStacks[bb] -= this.settings.bigBlindAmount;

    return new Game({
      allPlayers: this.players,
      buttonPlayer: this.buttonPlayer,
      gameStacks,
      pots: [],
      currentRound: 'pre-flop',
      currentBets: { [sb]: this.settings.smallBlindAmount, [bb]: this.settings.bigBlindAmount },
      allInPlayers: [],
      foldedPlayers: [],
      settledQueue: [],
      actQueue: generateQueueForRound(this.players, this.players, this.buttonPlayer, 'pre-flop'),
      potResults: [],
      settings: this.settings,
    });
  }

  addNewPlayer(playerId: PlayerId) {
    this.players.push(playerId);
    this.tableStacks[playerId] ??= this.settings.defaultStartingAmount;
  }

  addPreviousGame(game: Game) {
    this.previousGames.push(game);
    const winnerChipMap = game.getWinnerChipMap();

    this.tableStacks = mergePlayerChipMap(game.gameStacks, winnerChipMap);

    this.buttonPlayer = this.players[this.players.indexOf(game.buttonPlayer) + 1] ?? this.players[0];

    function mergePlayerChipMap(a: PlayerChipMap, b: PlayerChipMap): PlayerChipMap {
      const c = Object.assign({}, a);
      for (const key in b) c[key] += b[key];
      return c;
    }
  }

  addCurrentGameToPreviousGames() {
    if (this.currentGame === null) { throw new Error('There must be a current game') };
    this.addPreviousGame(this.currentGame);
    this.currentGame = null;
  }
}

export class Game implements GameInterface {
  allPlayers!: PlayerId[];
  buttonPlayer!: PlayerId;
  gameStacks!: PlayerChipMap;
  pots!: Pot[];
  currentRound!: GameRoundName;
  currentBets!: PlayerChipMap;
  allInPlayers!: PlayerId[];
  foldedPlayers!: PlayerId[];
  settledQueue!: PlayerId[];
  actQueue!: PlayerId[];
  potResults!: Record<PlayerId, WLSOption>[];
  settings!: GameSettings;

  fromJSON(s: string) { Object.assign(this, JSON.parse(s)); };
  toJSON() { return this; }
  copy() { return new Game(JSON.parse(JSON.stringify(this))); }

  constructor(obj: GameInterface) {
    Object.assign(this, obj);
  }

  get currentPlayer(): PlayerId {
    return this.actQueue[0];
  }

  get currentPlayerActions(): PlayerAction[] {
    const playerToAct = this.currentPlayer;
    const maxBetSoFar = Math.max(...Object.values(this.currentBets), 0);
    const playerBet = this.currentBets[playerToAct] ?? 0;
    const diff = maxBetSoFar - playerBet;
    const playerStack = this.gameStacks[playerToAct];
    const result: PlayerAction[] = [{ type: "fold" }];

    const minimumBet = this.settings.bigBlindAmount;

    if (diff === 0) {
      result.push({ type: "check" });

      if (playerStack <= minimumBet)
        result.push({ type: "raise", allIn: true, addedAmount: playerStack, totalAmount: playerBet + playerStack });
      else
        result.push({ type: "raise", allIn: false, addedAmount: minimumBet, totalAmount: playerBet + minimumBet });
    } else {
      if (playerStack <= diff)
        result.push({ type: "call", allIn: true, addedAmount: playerStack, totalAmount: playerBet + playerStack });
      else
        result.push({ type: "call", allIn: false, addedAmount: diff, totalAmount: maxBetSoFar });
      if (playerStack <= diff + minimumBet) {
        if (diff < playerStack)
          result.push({ type: "raise", allIn: true, addedAmount: playerStack, totalAmount: playerBet + playerStack });
      }
      else
        result.push({ type: "raise", allIn: false, addedAmount: diff + minimumBet, totalAmount: playerBet + diff + minimumBet });
    }
    return result;
  }

  act(action: PlayerAction) {
    Assert.expect(this.currentRound).ne('showdown');
    Assert.expect(this.actQueue).beNonEmpty();

    const currentPlayer = this.actQueue.shift()!;
    const currentBet = this.currentBets[currentPlayer] ??= 0;
    let newCurrentBet = currentBet;
    const currentStack = this.gameStacks[currentPlayer];
    let newCurrentStack = currentStack;
    validateAction(action, currentBet, currentStack, this.settings.bigBlindAmount);

    if (action.type === 'fold') {
      // Remove this player from all pots
      this.pots.forEach(pot => { pot.players = pot.players.filter(p => p !== currentPlayer); });
      this.foldedPlayers.push(currentPlayer);

    } else if (action.type === 'check') {
      this.settledQueue.push(currentPlayer);

    } else if (action.type === 'call') {
      newCurrentStack = currentStack - action.addedAmount;
      newCurrentBet = currentBet + action.addedAmount;
      if (action.allIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        this.settledQueue.push(currentPlayer);
      }

    } else if (action.type === 'raise') {
      newCurrentStack = currentStack - action.addedAmount;
      newCurrentBet = currentBet + action.addedAmount;
      this.actQueue.push(...this.settledQueue);
      this.settledQueue = [];
      if (action.allIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        this.settledQueue.push(currentPlayer);
      }

    } else {
      Assert.never();
    }

    this.currentBets[currentPlayer] = newCurrentBet;
    this.gameStacks[currentPlayer] = newCurrentStack;

    // If there is only one player left to act, automatically act 'check' for it
    // if (this.allPlayers.length - this.allInPlayers.length - this.foldedPlayers.length === 1) {
    if (this.allPlayers.length - this.foldedPlayers.length === 1) {
      this.settledQueue.push(this.actQueue.pop()!);
    }

    if (this.actQueue.length === 0) {
      let nextRound: GameRoundName;
      const newActQueue = generateQueueForRound(this.allPlayers, this.settledQueue, this.buttonPlayer, 'post-pre-flop');

      const inGamePlayers = [...this.allInPlayers, ...this.settledQueue];
      const foldedPlayers = this.foldedPlayers;
      Assert.expect(inGamePlayers.length + foldedPlayers.length).eq(this.allPlayers.length);

      let inGameBets: Record<PlayerId, ChipAmount> = {}, foldedBets: Record<PlayerId, ChipAmount> = {};
      for (const [player, amount] of Object.entries(this.currentBets)) {
        if (amount === 0) continue;
        if (inGamePlayers.includes(player)) {
          inGameBets[player] = amount;
        } else {
          foldedBets[player] = amount;
        }
      }

      this.currentBets = {};

      const newPots: Record<string, number> = {};

      while (true) {
        const minimumBet = R.reduce<number, number>(R.min, Infinity, R.values(inGameBets));
        if (minimumBet === Infinity) break;

        const newInGameBets: typeof inGameBets = {};
        let accumulated = 0;
        for (const [player, bet] of Object.entries(inGameBets)) {
          const newBet = bet - minimumBet;
          if (newBet > 0) {
            newInGameBets[player] = newBet;
            accumulated += minimumBet;
          } else if (newBet === 0) {
            accumulated += minimumBet;
          } else {
            Assert.never('newBet should always be non-negative. player=' + player);
          }
        }

        Assert.expect(accumulated % minimumBet).eq(0);

        const newFoldedBets: typeof foldedBets = {};
        for (const [player, bet] of Object.entries(foldedBets)) {
          const newBet = Math.max(0, bet - minimumBet);
          if (newBet > 0) {
            newFoldedBets[player] = newBet;
            accumulated += minimumBet;
          } else {
            accumulated += bet;
          }
        }

        const oldBets = R.sum(R.map(R.values, [inGameBets, foldedBets]).flat());
        const newBets = R.sum(R.map(R.values, [newInGameBets, newFoldedBets]).flat());
        Assert.expect(oldBets - newBets).eq(accumulated);
        Assert.expect(accumulated).gt(0);

        const involvedPlayersStr = JSON.stringify(R.keys(inGameBets).sort());
        Assert.expect(involvedPlayersStr in newPots).beFalsy();
        newPots[involvedPlayersStr] = accumulated;

        inGameBets = newInGameBets;
        foldedBets = newFoldedBets;
      }

      for (const [involvedPlayersStr, amount] of Object.entries(newPots)) {
        const existingPotIndex = this.pots.findIndex((pot) => JSON.stringify(pot.players) === involvedPlayersStr);

        if (existingPotIndex >= 0) {
          this.pots[existingPotIndex].amount += amount;
        } else {
          this.pots.push({
            players: JSON.parse(involvedPlayersStr),
            amount,
          })
        }
      }

      if (newActQueue.length === 0 || newActQueue.length === 1) {
        this.settledQueue = [];
        nextRound = 'showdown';
      } else {
        this.settledQueue = [];
        this.actQueue = newActQueue;
        const gameRounds: GameRoundName[] = ["pre-flop", "flop", "turn", "river", "showdown"];
        nextRound = gameRounds[gameRounds.indexOf(this.currentRound) + 1];
        Assert(nextRound !== undefined && nextRound !== 'pre-flop');
      }

      if (nextRound === 'showdown') {
        this.potResults = this.pots.map((pot) => pot.players.length === 1 ? { [pot.players[0]]: WLSOption.Win } : {});
      }

      this.currentRound = nextRound;
    }
  }

  toString(): string {
    let result = "";
    // Pots
    if (this.pots.length === 1)
      result += `Pot: ${this.pots[0].amount}\n`;
    else {
      result += `Pots:\n`;
      for (const p of this.pots)
        result += `- ${p.amount} (${p.players.join(', ')})\n`;
    }

    // Round
    result += `Round: ${this.currentRound}\n`;
    result += `\n`;

    // Players
    for (const p of this.settledQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;
    result += '> ';
    for (const p of this.actQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;

    return result;
  }


  getPotResultsForPlayer(playerId: PlayerId) {
    Assert.expect(this.currentRound).eq('showdown');

    return this.potResults.map((potResult, index) => {
      let text = '', choiceNeeded = false;
      if (this.foldedPlayers.includes(playerId)) {
        text = 'You folded';
      } else if (!this.pots[index].players.includes(playerId)) {
        text = 'Not involved';
      } else if (this.pots[index].players.length === 1) {
        Assert.expect(this.pots[index].players[0]).eq(playerId, 'Should be handled in previous case');
        text = 'You won the pot';
      } else {
        choiceNeeded = true;
      }

      if (!choiceNeeded)
        return {
          text,
          choiceNeeded,
          settled: this.isPotSettled(index),
          potAmount: this.pots[index].amount,
        };

      const chosenOption = potResult[playerId];
      const involvedPlayers = this.pots[index].players;
      const allChoices = Object.values(potResult);
      const everyonePicked = allChoices.length === involvedPlayers.length;
      const settled = everyonePicked && isConsistent(allChoices, true);

      text = '';
      if (!chosenOption)
        text = 'Please select an option';
      else if (!everyonePicked)
        text = 'Waiting for everyone involved to make a choice';
      else if (!settled)
        text = 'Please re-select pot result';


      let hintedOption;
      if (chosenOption) {
        if (!settled && everyonePicked)
          hintedOption = deriveHintedOption(allChoices, true);
      } else {
        hintedOption = deriveHintedOption(allChoices, involvedPlayers.length - allChoices.length === 1);
      }
      return {
        text,
        choiceNeeded,
        settled,
        chosenOption,
        hintedOption,
        potAmount: this.pots[index].amount,
      }
    });
  }

  setPotResultForPlayer(playerId: PlayerId, potIndex: number, wlsOption: WLSOption) {
    Assert.expect(this.currentRound).eq('showdown', 'Can only set player result during showdown');
    Assert.expect(this.pots[potIndex]).beTruthy('potIndex out of bound');
    Assert.expect(this.pots[potIndex].players.includes(playerId)).beTruthy('The specified playerId does not exist in the potIndex');
    this.potResults[potIndex][playerId] = wlsOption;
  }

  isPotSettled(index: number) {
    const involvedPlayers = this.pots[index].players;
    const allChoices = Object.values(this.potResults[index]);
    const everyonePicked = allChoices.length === involvedPlayers.length;
    const settled = everyonePicked && isConsistent(allChoices, true);
    return settled;
  }

  get isAllPotsSettled() {
    for (let i = 0; i < this.pots.length; i++) {
      if (!this.isPotSettled(i))
        return false;
    }
    return true;
  }

  getWinnerChipMap(): PlayerChipMap {
    Assert.expect(this.isAllPotsSettled).beTruthy();
    const winnerChipMap: PlayerChipMap = {};

    this.potResults.forEach((potResult, index) => {
      const winners = Object.entries(potResult).flatMap(([playerId, wls]) => wls !== WLSOption.Lose ? playerId : []);
      const originalPot = this.pots[index];
      Assert.expect(winners).beNonEmpty('There must be winners');
      const winningAmount = originalPot.amount / winners.length;
      for (const winner of winners) {
        winnerChipMap[winner] ??= 0;
        winnerChipMap[winner] += winningAmount;
      }
    });

    return winnerChipMap;
  }
}

function validateAction(action: PlayerAction, currentBetBeforeThisAct: ChipAmount, currentStackBeforeThisAct: ChipAmount, minimumBet: ChipAmount) {
  switch (action.type) {
    case 'check':
    case 'fold':
      break;
    case 'call':
    case 'raise':
      if (action.addedAmount > currentStackBeforeThisAct) throw new Error('betted more than stack');
      if ((action.addedAmount === currentStackBeforeThisAct) !== action.allIn) throw new Error('all-in does not match');
      if (action.addedAmount + currentBetBeforeThisAct !== action.totalAmount) throw new Error('added + betted !== total');
      if (action.totalAmount < minimumBet) throw new Error('addedAmount must be at least be big blind');
      break;
    default:
      Assert.never();
  }
}
